import { components } from "~/openapi/schema";
import { strapiRepository } from "~/openapi/strapiRepository";

export const ContactBox = ({
  contact,
}: {
  contact: components["schemas"]["Employee"];
}) => {
  const { getImageUrl } = strapiRepository;

  return (
    <div className={"relative flex aspect-video flex-col items-end"}>
      {contact.photo && (
        <img
          className={"absolute size-full object-cover"}
          src={getImageUrl(contact.photo)}
          alt={contact.name}
        />
      )}
      <div className={"absolute flex flex-col items-end p-3"}>
        <h2
          className={
            "w-fit bg-foreground/90 px-3 py-1 text-3xl font-bold text-white xl:text-4xl"
          }
        >
          {contact.name}
        </h2>
        <p
          className={
            "text-md w-fit bg-foreground/90 px-3 py-1 text-white xl:text-lg"
          }
        >
          {contact.role}
        </p>
        <a
          href={`mailto:${contact.email}`}
          className={
            "w-fit bg-foreground/90 px-3 py-1 text-sm text-white/90 lg:text-base"
          }
        >
          {contact.email}
        </a>
        {/*tel*/}
        <a
          href={`tel:${contact.phone}`}
          className={
            "w-fit bg-foreground/90 px-3 py-1 text-sm text-white/90 lg:text-base"
          }
        >
          {contact.phone}
        </a>
      </div>
    </div>
  );
};
